import React from 'react';
import {
  StylesBanner,
  StylesUpgradeNowButton,
} from '../YourFreeTrialEndsBanner/styles';
import Grid from '@mui/material/Grid';
import LightningIcon from '../../images/LightningIcon';
import { useAppSelector } from '../../../store';
import { useAuth } from '../../../hooks';
import usePayment from '../../../features/payment/hooks/usePayment';
import useAccountLimits from '../../../features/auth/hooks/useAccountLimits';

const YouHaveCanceledYourPlanBanner = () => {
  const { onGetBilling, onGetMyAccount } = useAuth();
  const { onReactivateSubscription } = usePayment();
  const { onSetNoticeAccountDeletionCancelledModal } = useAccountLimits();

  const billing = useAppSelector(state => state.auth.payment.billing);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const myAccount = useAppSelector(state => state.auth.myAccount);

  const handleReactivateSubscription = () => {
    if (currentAccount?.accountId && billing?.subscriptionId) {
      onReactivateSubscription({
        id: currentAccount.accountId,
        data: { subscriptionId: billing.subscriptionId },
        successFn: () => {
          onGetMyAccount(currentAccount.accountId);
          onGetBilling({ id: currentAccount.accountId });
          myAccount?.deletedAt &&
            onSetNoticeAccountDeletionCancelledModal(true);
        },
      });
    }
  };

  return (
    <StylesBanner container justifyContent={'space-between'} xs={12}>
      <Grid
        display={'flex'}
        alignItems={'center'}
        xs={12}
        justifyContent={'center'}
        gap={'16px'}
      >
        <Grid>
          <Grid>{`You have canceled your plan. Your subscription will expire on ${
            billing?.subscriptionExpirationDate || ''
          }`}</Grid>
        </Grid>
        <StylesUpgradeNowButton
          variant='contained'
          size='small'
          $padding='8px 14px'
          startIcon={<LightningIcon />}
          onClick={handleReactivateSubscription}
        >
          Reactivate Subscription
        </StylesUpgradeNowButton>
      </Grid>
    </StylesBanner>
  );
};

export default YouHaveCanceledYourPlanBanner;
